import React from 'react';
import {getFormattedFieldValueFromResponse} from '../../../services/utils/RequestDetailsFormatters';
import {TeamsTable} from "../../Table/TeamsTable";
import {TeamsTableCell} from "../../Table/TeamsTableCell";

interface DetailsPanelProps {
    response: any;
    detailDefinition: any;
}

export const Detail = ({response, detailDefinition}: DetailsPanelProps) => {
    const formattedValue = getFormattedFieldValueFromResponse(response, detailDefinition.valueGetter, detailDefinition.formatter);

    if (detailDefinition.hideIfEmpty && (formattedValue == null || formattedValue.length == 0)) {
        return null;
    }

    return (
        <TeamsTable>
            <tbody>
                <tr>
                    <TeamsTableCell>
                        <strong>{detailDefinition.displayName}:{' '}</strong>
                    </TeamsTableCell>
                    <TeamsTableCell>
                        {formattedValue}
                    </TeamsTableCell>
                </tr>
            </tbody>
        </TeamsTable>
    );
};
