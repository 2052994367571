import {currentUserVar} from "../../constants/cache";

export const canClaim = (ircRequest: any) => {
    return (isOpen(ircRequest) || isClaimed(ircRequest)) && ircRequest?.claimedBy !== currentUserVar();
}

export const canUnclaim = (ircRequest: any) => {
    return isClaimed(ircRequest) && ircRequest?.claimedBy === currentUserVar();
}

export const isClaimed = (request: any) => {
    return request?.ircRequestStatus === 'CLAIMED'
}
export const isApproved = (request: any) => {
    return request?.ircRequestStatus === 'APPROVED'
}

export const isOpen = (request: any) => {
    return request?.ircRequestStatus === 'OPEN'
}