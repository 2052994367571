import {queueOptions, Queue, tabNumberToQueue} from "../../constants/Queue";

export interface TabVariables {
    tabKey: number;
    tabName: string;
    queue: Queue;
}

export const isInEndState = (request: any) => {
    return request?.ircRequestStatus === 'PUBLISHED' || request?.ircRequestStatus === 'ARCHIVED' || request?.ircRequestStatus === 'EXPIRED'
}

export const getQueueCountDataGivenTab = (queues: {queue: string, count: number}[] | undefined, tabKey: number):  number | undefined => {
    const queueName: string = tabNumberToQueue[tabKey]
    return queueName != 'COMPLETED'? queues?.find((queue) => queue.queue == queueName)?.count : undefined;
}

export const createTabVariables = (tabKey: number) => {
    const queue = tabNumberToQueue[tabKey];
    const tabName = getDisplayName(queue);
    return {tabKey, tabName, queue};
}

export const getQueuesToCount = (currentQueue: string | undefined): string[] => {
    return queueOptions.filter(
        //We don't care about the number of requests in completed queue (published & expired requests)
        (queue) => queue !== currentQueue && queue !== 'COMPLETED'
    )
}

const getDisplayName = (queueName: String) => {
    if(queueName == null || queueName == undefined || queueName == 'READY') {
        return 'Open';
    }
    return queueName.charAt(0).toUpperCase() + queueName.slice(1).toLowerCase();
}