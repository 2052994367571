import {Card, CardBody, CardText, CardTitle} from '@gs-ux-uitoolkit-react/card';
import {Col, Row} from '@gs-ux-uitoolkit-react/layout';
import {ClaimButton} from '../../Buttons/UpdateStatusButton';
import React, {useEffect, useState} from 'react';
import {ApproveModal} from '../../Approve/ApproveModal';
import {useQuery} from "@apollo/client";
import {GET_REQUEST_DETAILS} from "../../../constants/GraphqlCallDefinitions";
import {RequestTitle} from "./RequestTitle";
import {UnclaimButton} from "../../Buttons/UnclaimButton";
import {AdditionalActionButtons} from "../../Buttons/AdditionalActionButtons";
import {
    canClaim, canUnclaim, isApproved, isClaimed,
} from "../../../services/utils/RequestHeaderUtils";

export const RequestHeader = () => {
    const {data} = useQuery(GET_REQUEST_DETAILS);

    return (
        <section>
            <Card elevation="00" style={{backgroundColor: "inherit"}}>
                <CardBody>
                    <CardTitle>
                        <RequestTitle data={data?.getIRCRequest}/>
                    </CardTitle>
                    <CardText>
                        <Row>
                            <Col>
                                <Row style={{gap: '5px'}}>
                                    {canUnclaim(data?.getIRCRequest) &&
                                        <UnclaimButton/>
                                    }
                                    {canClaim(data?.getIRCRequest) &&
                                        <ClaimButton actioner={data?.getIRCRequest?.claimedBy}/>
                                    }
                                    {(isClaimed(data?.getIRCRequest) || isApproved(data?.getIRCRequest)) &&
                                        <ApproveModal/>
                                    }
                                </Row>
                            </Col>
                            <Col>
                                <AdditionalActionButtons/>
                            </Col>
                        </Row>
                    </CardText>
                </CardBody>
            </Card>
        </section>
    );
}
