import {arrayIsNullOrEmpty} from "./RequestEntityUtils";

export const additionalContextsWithDisplayNames = (additionalContexts: Array<any>, securityMetadata: {
    displayName: string,
    ticker: string
}[]) => {
    return additionalContexts?.map((additionalContext) => {
        if (arrayIsNullOrEmpty(additionalContext.tickers) && arrayIsNullOrEmpty(additionalContext.industries)) {
            return {
                ...additionalContext,
                displayNames: ['No industry or security chosen']
            };
        }

        if (additionalContext.tickers?.length > 0) {
            const tickers = securityMetadata
                ? additionalContext.tickers.map((ticker: any) => matchTickerToSecurity(ticker, securityMetadata))
                : additionalContext.tickers;

            const displayNames = additionalContext.industries?.length > 0
                ? tickers.concat(additionalContext.industries)
                : tickers;

            return {
                ...additionalContext,
                displayNames
            };
        }

        return {
            ...additionalContext,
            displayNames: additionalContext.industries
        };
    });
};


export const getAdditionalContextForOtherChange = (additionalContexts: Array<any>): any[] => {
    return additionalContexts ? additionalContexts?.filter((context) => context.nonQuantumChange === true) : [];
}

export const getAdditionalContextWithoutOtherChanges = (additionalContexts: Array<any>): any[] => {
    return additionalContexts ? additionalContexts?.filter((context) => context.nonQuantumChange === false || context.nonQuantumChange === undefined) : [];
}

const matchTickerToSecurity = (ticker?: string, securityMetadata?: { displayName: string, ticker: string }[]) => {
    if (!ticker || ticker.length == 0) return '';
    const security = securityMetadata?.find((security) => security.ticker === ticker);
    if (!security) {
        return ticker;
    }
    return `${security.displayName} (${ticker})`;
}

export const returnCommaIfNotLastValue = (index: number, listSize: number) => {
    return index <= listSize - 2 ? ', ' : '';
}
