import {gql} from '@apollo/client';

export const GET_REQUEST_DETAILS = gql`
    query getIRCRequest($ircRequestId: UUID!) {
        currentUser @client
        currentRequestId @client @export(as: "ircRequestId")
        getIRCRequest(ircRequestId: $ircRequestId) {
            ircRequestId
            version
            ircRequestStatus
            analystChatId
            lastUpdated
            analystChatId
            ircChatId
            claimedBy
            approvedBy
            peopleMetadata
            approvalDate
            ircQuantumChangeSetKeys
            approvalComment {
                comment
                author
                lastUpdated
            }
            ircSubmission {
                leadAnalystName
                tickers
                industries
                ircChangeTypes
                meetingRequired
                submissionDate
                wipDataSubmitted
                estimatedPublicationDate
                approvalPreMarketOpen
                additionalContexts {
                    tickers
                    context
                    industries
                }
                additionalContextReference
            }
			attachments {
				files {
					filename
					uri
				}
			}
			securityMetadata {
			    securityMetadata {
			        ticker
			        displayName
			    }
			}
        }
}`;

export const GET_REQUEST_DETAILS_COMMENT = gql`
    query getIRCRequest($ircRequestId: UUID!) {
        currentUser @client
        currentRequestId @client @export(as: "ircRequestId")
        getIRCRequest(ircRequestId: $ircRequestId) {
            approvalComment {
                comment
                author
                lastUpdated
            }
            peopleMetadata
        }
}`;

export const CREATE_CHAT = gql`
    mutation createChat($ircRequestId: UUID!, $chatType: ChatType!) {
        currentRequestId @client @export(as: "ircRequestId")
        createChat(
            ircRequestId: $ircRequestId,
            chatType: $chatType
        ) {
            chatType
            chatId
        }
    }`;

export const UPDATE_STATUS_ACTIONER = gql`
    mutation updateIRCRequestAttributes($ircRequestId: UUID!, $updatedAttributes: IRCRequestUpdateInput!) {
        currentRequestId @client @export(as: "ircRequestId")
        updateIRCRequestAttributes(
        	ircRequestId: $ircRequestId,
        	updatedAttributes: $updatedAttributes
		) {
            ircRequestId
            ircRequestStatus
        }
}`;

export const UPDATE_REQUEST_APPROVERS = gql`
    mutation updateIRCRequestAttributes($ircRequestId: UUID!, $updatedAttributes: IRCRequestUpdateInput!) {
        currentRequestId @client @export(as: "ircRequestId")
        updateIRCRequestAttributes(
            ircRequestId: $ircRequestId, 
            updatedAttributes: $updatedAttributes
        ) {
        ircRequestId
        approvedBy
        approvalDate
    }
}`;

export const QUERY_BY_QUEUE = gql`query getIRCRequestsByQueue($queryCriteriaValue: IRCQueueQuery!) {
    getIRCRequestsByQueue(queryCriteria: $queryCriteriaValue) {
        results {
            ircRequestId
            ircRequestStatus
            ircRequestRegion
            ircSubmission {
                leadAnalystName
                tickers
                industries
                ircChangeTypes
                estimatedPublicationDate
                approvalPreMarketOpen
                wipDataSubmitted
                meetingRequired
            }
            ircQuantumChangeSetKeys
            peopleMetadata
            lastUpdated
            claimedBy
            approvedBy
            approvalDate
        }
        queueCounts {
            queue
            count
        }
    }
}`;

export const GET_WIP_DATA = gql`
    query getRequestQuantumData($ircRequestIdValue: UUID!) {
        currentUser @client
        currentRequestId @client @export(as: "ircRequestIdValue")
        getRequestQuantumData(ircRequestId: $ircRequestIdValue) {
            ircRequestId
            securityKey
            ticker
            changeSetId
            issuerName
            involvedChanges{
                keyword
                currency
                wipValue
                liveValue
            }
        changeTypes
    }
}`;

export const GET_APPROVAL_REQUIREMENTS = gql`
    query getApprovalRequirements($ircRequestId: UUID!) {
        currentUser @client
        currentRequestId @client @export(as: "ircRequestId")
        getApprovalRequirements(ircRequestId: $ircRequestId) {
            eligibleApprovers
            minimumNumberApprovers
            peopleMetadata
        }
    }`;

export const GET_ADDITIONAL_CONTEXT = gql`
    query getAdditionalContext($ircRequestId: UUID!) {
        currentUser @client
        currentRequestId @client @export(as: "ircRequestId")
        getAdditionalContext(ircRequestId: $ircRequestId) {
            additionalContexts {
                nonQuantumChange
                changeType
                context
                industries
                tickers
                industries
            }
        }
    }`;

export const GET_USER_PERMISSIONS = gql`
    query getUserActionList($ircRequestIdValue: UUID!) {
        getUserActionList(ircRequestId: $ircRequestIdValue) {
            allowedAccess {
                UPDATE
                CLAIM
                APPROVE
                ARCHIVE
            }
        }
    }`;
