import React from 'react';
import { detailsPanelDefinition } from './DetailsPanelDefinitions';
import { Detail } from "./Detail";
import { AccordionPanel } from "@gs-ux-uitoolkit-react/accordion";

interface DetailsPanelProps {
    response: any;
    onCollapse: any;
    onExpand: any;
}

export const DetailsPanel = ({ response, onCollapse, onExpand }: DetailsPanelProps) => {
    const REQUEST_DETAILS = 'Request Details';
    const panel = detailsPanelDefinition.map((definition, index) => {
        return <Detail key={index} response={response} detailDefinition={definition} />;
    }).filter(Boolean); // filters out undefined/null Details

    return (
        <AccordionPanel
            header={REQUEST_DETAILS}
            defaultExpanded
            onCollapse={() => onCollapse(REQUEST_DETAILS)}
            onExpand={() => onExpand(REQUEST_DETAILS)}
        >
            {panel}
        </AccordionPanel>
    );
};